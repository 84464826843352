body {
    background: #ffffff !important;
    overflow-x: hidden !important;
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
}
main {
    padding: 0 !important;
}
p {
    font-size: 17px;
}
h1, h2, h3, h4, h5 {
    font-family: 'Orbitron', sans-serif !important;
}
a {
    text-underline-position: under;
    font-family: 'Orbitron', sans-serif !important;
}
button {
    text-underline-position: under;
    font-family: 'Orbitron', sans-serif !important;
}
.dark_gray {
    color: #353535;
}
hr {
    width: 50%;
    max-width: 200px;
    height: 3px;
    border: 3px solid #eeeeee;
}
.PricingTable {
    border-radius: 20px !important;
}
.MuiTabs-indicator {
    display: none;
}
.MuiButton-contained {
    text-transform: uppercase;
    font-weight: 600;
}
.games_break {
    border-bottom: 1px solid gray;
    padding: 25px 15px !important;
}
.edit_button_container {
    right: 20px;
    margin-top: -25px;
    position: absolute;
    text-align: center;
    color: #ffffff;
    box-shadow: 1px 1px 2px #a4a4a4;
    padding: 3px 7px;
    border-radius: 4px;
    background: #000000;
    font-family: 'Orbitron', sans-serif !important;
    box-shadow: 1px 1px 5px #13c1e0;
}
.games_break .edit_button {
    width: 40px !important;
    color: #ffffff;
    background-color: #000000 !important;
    font-family: 'Orbitron', sans-serif !important;
}
.games_break h4 {
    margin: 0;
    text-align: center;
}
.games_break h1, .games_break h3, .games_break h2 {
    color: #696969;
    margin: 0;
    text-align: center;
}
.edit_icon {
    height: 15px !important;
}
.remove_red {
    color: orangered;
    height: 20px !important;
    padding-top: 5px;
}
.assists_h1 {
    color: #43a9cd !important;
}
.goals_h1 {
    color: #13c1e0 !important;
}
.delete_game {
    position: absolute;
    margin-top: -10px;
    color: orangered;
    border: 1px solid orangered;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 10px;
    font-family: 'Orbitron', sans-serif !important;
}
.delete_game_confirm {
    height: 40px;
    padding: 7px;
    border-radius: 7px;
    border: 2px solid orangered;
    width: 100%;
    background: orangered;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}
.header {
    /*background: linear-gradient(90deg, #000000, #666666, #ffffff);*/
    color: #ffffff !important;
    background: transparent !important;
    z-index: 2;
    position: absolute !important;
    box-shadow: none !important;
}
.header img {
    padding-top: 10px;
}
.header u {
    text-underline-position: under;
}
.header .MuiFab-root {
    background-color: transparent !important;
    border-radius: 4px;
    padding: 0 32px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.header a:hover, .header button:hover {
    background-color: rgba(108, 103, 103, 0.6) !important;
}
.no_shadow {
    box-shadow: none !important;
}
.dark_mini {
    /*background-color: rgb(53 53 53);*/
    background: linear-gradient(180deg, #000000, #666666, rgba(0, 0, 0, 0.9));
    left: 0;
    position: relative;
    width: 100%;
    padding-top: 120px;
    text-align: center;
    color: white;
    margin-top: -2px;
}
.dark_jumbo {
    /*background: linear-gradient(90deg, #000000, #666666, #ffffff);*/
    background-image: url("./images/hockey-skates.jpeg");
    background-size: cover;
    /*background-repeat: no-repeat;*/
    /*background-attachment: fixed;*/
    background-position: center;
    top: 0;
    left: 0;
    position: relative;
    width: 100%;
    text-align: center;
    padding: 22vh 25px 100px 25px;
    color: white !important;
    height: 90vh;
    z-index: 1;
    text-shadow: 0 0 5px rgba(0,0,0,0.1);
    box-shadow: -2px 1px 20px 6px black;
}
.dark_jumbo h5 {
    color: #ffffff !important;
}
.homepage_intro {
    background: rgba(0,0,0,0.8);
    margin: 10px auto;
    padding: 50px 25px;
    box-shadow: 0 0 10px #ffffff;
    max-width: 800px;
    border-radius: 8px;
}
.homepage_intro p {
    padding: 10px;
}
.down_arrow_container {
    position: absolute;
    bottom: 0;
}
.down_arrow {
    background-image: url("./images/arrow-down.gif");
    filter: brightness(100);
    background-size: cover;
    width: 60px;
    height: 60px;
}
.home_container {
    padding: 0 20px;
    position: relative;
}
.home_container:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    max-width: 1000px;
    max-height: 800px;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    /*background-image: url("./images/quote.png");*/
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}
.custom_modal_styles .MuiButton-contained,
.add_game_modal .MuiButton-contained,
.dark_jumbo .MuiButton-contained {
    background: #000000;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
    width: 50%;
    max-width: 250px;
    box-shadow: 0px 0px 5px #13c1e0;
}
.custom_modal_styles {
    text-align: left !important;
}
.custom_modal_styles button,
.custom_modal_styles .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    max-width: 100% !important;
}

.add_game_modal {
    padding: 20px 0 0 0;
    text-align: center;
}
.body_jumbo {
    text-align: center;
}
.body_jumbo .MuiButton-contained {
    background: #000000;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 15px;
    width: 70%;
    max-width: 300px;
    box-shadow: 1px 1px 5px #13c1e0;
}
.left {
    width: 40%;
    float: left;
    text-align: center;
}
.right {
    width: 40%;
    float: right;
    text-align: center;
}
.pb-0 {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}
.pb-5 {
    padding-bottom: 5px !important;
}
.center_text {
    text-align: center;
}
.red {
    color: #fd875c;;
}
.green {
    color: #13c1e0;
}
.blue {
    color: #43a9cd;
}
.eee {
    color: #eeeeee;
}
.eee i {
    font-weight: 200;
}
.MuiAccordionSummary-expandIcon {
    /*background-color: rgb(53 53 53) !important;*/
    color: #353535 !important;
    /*color: #13c1e0 !important;*/
    /*text-shadow: 1px 1px 2px #c0f536;*/
}
.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}
.accord_pop {
    box-shadow: 1px 1px 10px #353535 !important;
    color: #353535 !important;
}
.accord_pop h2 {
    text-align: center;
}
.update_record_model hr {
    border: 3px solid #ffffff;
}
.metrics_in_editor {
    margin-bottom: 50px;
    padding-left: 25px;
}
.metrics_in_editor h3 {
    font-weight: 400;
}
.update_record_model .save_and_close {
    background: #000000 !important;
    color: #ffffff;
    position: fixed;
    bottom: 1.5% !important;
    width: 97% !important;
    margin: 8px auto !important;
    box-shadow: 1px 1px 5px #13c1e0;
}
.MuiBox-root-5 {
    background-color: #353535 !important;
}
.MuiButtonBase-roo .MuiTab-root.MuiTab-textColorInherit.Mui-selected,
.Mui-selected {
    color: #13c1e0 !important;
    border-bottom: 3px solid #13c1e0 !important;
}
.PrivateTabIndicator-colorSecondary-8,
.PrivateTabIndicator-colorSecondary-16 {
    background-color: #13c1e0 !important;
}
.MuiAccordionDetails-root {
    justify-content: center !important;
}
.charts {
    width: 100% !important;
}
iframe {
    border: none;
    width: 100%;
    height: 150% !important;
    max-height: 3000px;
    position: absolute;
    left: 0;
}
.ordinal-content-combination {
    font-size: 14px;
}
.assists_header {
    color: #75aec2;
}
.demo_video {
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 50px;
    /*zoom: 0.3;*/
}
.demo_modal {
}
.close_button {
    position: fixed !important;
    border-radius: 7px !important;
    border: 3px solid #2c2828 !important;
    background: #ffffff !important;
    font-weight: bold !important;
    padding: 10px !important;
    margin: 0 auto !important;
    width: 100% !important;
    height: 50px !important;
    z-index: 20 !important;
}
sup i {
    text-align: left;
    display: block;
}
.price {
    width: 90%;
    max-width: 700px;
    height: auto;
    border-radius: 15px;
    background: white;
    z-index: 122;
    font-size: 24px;
    padding: 25px 25px 0px 25px;
    margin: 0 auto;
    border: 2px solid #353535;
    border-top: 35px solid #353535;
}
.price h2 {
    margin-top: 20px;
}
.price h2 small {
    display: block;
    margin: 1px;
}
.price p {
    margin-top: -55px;
}
.price sup, .price sub, .price small, .price ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 2;
    list-style: circle;
}
.price_content {
    font-size: 14px;
    font-weight: 400;
}
.price_content svg {
    font-size: 14px;
    padding-top: 3px;
    color: #13c1e0;
}
.price small {
   margin-top: -20px;
}
.image_viewer {
    display: inline-block;
    position: relative;
    z-index: 2;
}
.image_viewer img {
    width: 55%;
    border: 2px solid #353535;
    border-radius: 10px;
    position: relative;
}
.img_l {
    left: 10px;
    margin-top: 50px;
    float: left;
}
.img_r {
    right: 10px;
    margin-top: -80px;
    float: right;
}
.left_align {
    text-align: left;
}
.white {
    color: white;
}
.pt-5 {
    padding-top: 15px !important;
}
.pb-15 {
    padding-bottom: 15px !important;
}
.pb-25 {
    padding-bottom: 25px !important;
}
.MuiRating-root {
    color: #13c1e0 !important;
}
.disclaimer_home {
    display: block;
    margin: 15px;
}
.m-5-p {
    margin: 5%;
}
.mt-5 {
    margin-top: 5px !important;
}
.m10 {
    margin: 10px;
}
.m20 {
    margin: 20px;
}
/*.MuiAccordionDetails-root {*/
/*    height: 500px;*/
/*}*/
.legend {
    text-align: center;
}
.legend span {
    color: white;
    font-size: 11px;
    font-weight: bold;
    padding: 5px;
    margin: 0 5px 0 10px;
    display: inline-block;
}
.legend .ALL {
    background-color: rgba(15, 13, 16, 0.39);
}
.legend .OMGHA {
    background-color: rgba(234,112,13,0.69);
}
.legend .SDP {
    background-color: rgba(25,158,229,0.69);
}
.legend .MNMADE {
    background-color: rgba(33,30,92,0.69);
}
.legend .HF {
    background: linear-gradient(90deg,rgba(153,182,78,0.49),rgba(153,182,78,0.99));
}
.legend .OTHER {
    background-color: rgba(182, 15, 148, 0.69);
}
.overall_stats_table {
    padding: 15px;
}
.footer {
    background-color: #000000;
    width: 100%;
    height: 20px;
    display: none;
}
.footer small {
    vertical-align: text-bottom;
}
.footer a {
    color: #eeeeee !important;
    font-size: 10px;
    text-decoration: none;
}
.p-15 {
    padding: 15px !important;
}
#zf_div_66m2zlPJ7xmDk7dN5j2zOiR9GkIV7jIqKFLo8XbJLNs iframe {
    position: static !important;
}
.PrivateTabIndicator-colorSecondary-9, .PrivateTabIndicator-colorSecondary-17 {
    background-color: #13c1e0 !important;
}
.contact_form {
    height: 450px;
}
#demo_page_text {
    background-color: black;
    color: #13c1e0;
    position: fixed;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    height: 50px;
    padding: 5px;
    z-index: 100;
    font-size: 13px;
}
.incrementer {
    width: 1.25em !important;
    height: 1.25em !important;
}
.PositionEditControls {
    padding-left: 10px !important;
}
.scoreboard_editor_out {
    background: #353535;
    border-radius: 18px;
    color: #fefefe;
    padding: 5px;
    margin-top: 15px;
}
.scoreboard_editor_in {
    display: grid;
    text-align: center;
    border: 3px solid #fefefe;
    border-radius: 15px;
}
.scoreboard_top_row {
    display: inline-flex;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px
}
.scoreboard_bottom_row {
    display: inline-flex;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0 15px 0;
}
.scoreboard_goals {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #fefefe;
    margin: 5px 25px 0 25px;
    padding: 3px;
    font-size: 30px;
    color: #fcff93;
    font-weight: 400;
}
.scoreboard_time_date {
    height: 60px;
    border: 1px solid #fefefe;
    border-radius: 5px;
    padding-top: 15px;
    font-size: 20px;
}
.control_buttons {
    margin-left: 22px;
}
.scoreboard_controls span {
    display: block;
}
svg.MuiSvgIcon-root.incrementer {
    position: absolute;
}
svg.MuiSvgIcon-root.score_minus_first.incrementer {
    margin: 35px 0 0 -68px;
}
svg.MuiSvgIcon-root.score_minus.incrementer {
    margin: 35px 0 0 -70px;
}
svg.MuiSvgIcon-root.score_add.incrementer.green {
    margin: -40px 0 0 17px;
}
/* scoreboard shots */
svg.MuiSvgIcon-root.shots_minus_first.incrementer {
    margin: 15px 0 0 -68px;
}
svg.MuiSvgIcon-root.shots_minus.incrementer {
    margin: 15px 0 0 -70px;
}
svg.MuiSvgIcon-root.shots_add.incrementer.green {
    margin: -40px 0 0 17px;
}
/*.data-card {*/
/*    border: 1px solid #e4e4e4;*/
/*    border-radius: 10px;*/
/*    padding: 15px;*/
/*    display: inline-block;*/
/*    background: #f8f8f8;*/
/*    box-shadow: 0px 0px 5px 0px #80808075;*/
/*    margin-top: 25px;*/
/*}*/
.data_card {
    padding: 5px !important;
    border: 0 !important;
    margin: 0 auto;
    top: 0 !important;
    bottom: 0 !important;
    height: 100vh !important;
    overflow: scroll !important;
    transform: none !important;
    left: 0 !important;
    width: 100% !important;
}
.mr-15 {
    margin-right: 15px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.mt-50 {
    margin-top: 50px;
}
.mb-50 {
    margin-bottom: 50px;
}
.m-5 {
    margin: 5px !important;
}
.m-10 {
    margin: 10px !important;
}
.metrics_accordion h2 {
    margin-bottom: 0;
    font-weight: 400;
}
.metrics_accordion small {
    text-align: center;
    display: block;
    margin: 5px 0;
}
.advertise {
    padding: 25px 0;
    border: 1px solid gray;
    margin-top: 25px;
    background: linear-gradient(45deg, #cdcccc, transparent);
}
.pre_save_msg {
    bottom: 0;
    position: fixed;
    margin: 0;
    color: #777777;
    left: 15px;
}
input[type="date"]::before {
    content: '📅'
}
input[type="date"] {
    width: 110px;
    color: #353535;
    text-align: center;
    font-size: 11px;
    border: none;
    border-bottom: 1px solid #cccccc;
}
canvas {
    padding-right: 15px;
}
.date_button {
    color: #13c1e0;
    font-size: 11px;
    background-color: white;
    border: 1px solid #13c1e0;
    border-radius: 7px;
    padding: 5px 10px;
    margin-left: 5px;
    max-width: 250px;
    font-weight: bold;
    text-transform: uppercase;
}
.game_details {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 10px 0;
    border-radius: 50px;
}
.game_details_width {
    max-width: 45% !important;
}
.pb-10 {
    padding-bottom: 10px;
}
.pt-20 {
    padding-top: 20px;
}
.mt--40 {
    margin-top: -40px;
    text-align: center;
}
.blue_top {
    border-top: 3px solid blue;
}
.blue_bottom {
    border-bottom: 3px solid blue;
}
.red_bottom {
    border-bottom: 3px solid red;
}
.game_details h4 {
    margin-top: 15px;
}
.game_details h3 {
    margin-bottom: 15px;
}
.mlf-10-p {
    margin: 0 10% !important;
}
.ledger_block {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    box-shadow: 0px 8px 16px rgb(0 0 0 / 10%);
    padding: 10px 5px;
}
.border-right {
    border-right: 1px solid rgb(0 0 0 / 20%)
}
.border-bottom  {
    border-bottom : 1px solid rgb(0 0 0 / 20%)
}
.pill {
    border: 1px solid #ffffff;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 5px;
    display: inline-block;
}
.team_score_titles {
    font-family: 'Orbitron', sans-serif !important;
}
.homepage_button {
    width: 90% !important;
}
/*.quote_quote {*/
/*    background-image: url("./images/quote.png");*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    background-position-x: left;*/
/*    margin: auto;*/
/*}*/
.green_highlight {
    background-color: rgba(130, 234, 130, 0.48);
    border: 4px solid rgba(130, 234, 130, 0.28);
}
.red_highlight {
    background-color: rgba(222, 125, 143, 0.48);
    border: 4px solid rgba(222, 125, 143, 0.28);
}
.social_icons {
    width: 50px;
    margin: 15px 10px;
}
@media screen and (max-width: 780px) {
    .add_home_screen_container {
        z-index: 7;
        width: 100%;
        margin: 0 auto;
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: center;
    }
    .add_app_to_home_screen {
        box-shadow: 1px 1px 5px skyblue;
        text-shadow: #2c2828;
        height: 60px;
        border-radius: 20px;
        background: white;
        padding: 10px 25px;
        border: 3px solid black;
        margin: 5px 10px;
        position: relative;
        bottom: 9px;
        left: 0;
        z-index: 5;
    }
    .triangle_rot {
        position: fixed;
        background: white;
        border-right: 2px solid black;
        border-bottom: 2px solid black;
        height: 20px;
        width: 20px;
        rotate: 45deg;
        bottom: 4px;
        left: 48%;
    }
    .close_icon {
        border: 3px solid #000000;
        background: #000000;
        color: #ffffff;
        width: 21px;
        position: absolute;
        right: 0px;
        padding: 0 10px 0 5px;
        text-align: center;
        top: 0px;
        border-bottom-left-radius: 7px;
        border-top-right-radius: 16px;
        border-right: 0;
        border-top: 0;
        font-weight: 600;
    }
}
.profile_pages_top {
    background: black;
    width: 100%;
    height: 105px;
    z-index: -1;
}